* {
	-webkit-touch-callout: none;
	/*-webkit-tap-highlight-color: rgba(255,255,255,0);*/
	-webkit-text-size-adjust: none;
	/*-webkit-user-select: none;*/
		-webkit-touch-callout: none; /* iOS Safari */
		  -webkit-user-select: none; /* Safari */
		   -khtml-user-select: none; /* Konqueror HTML */
			 -moz-user-select: none; /* Old versions of Firefox */
			  -ms-user-select: none; /* Internet Explorer/Edge */
				  user-select: none; /* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

@font-face {
	font-family: 'GoodBold';
	src: url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Bold.eot) format('eot');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Bold.woff) format('woff');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Bold.woff2) format('woff2');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Bold.ttf) format('truetype');
	font-display: swap;
	font-style: normal;
	font-weight: 600 700;
	font-weight: bold;
}

@font-face {
	font-family: 'GoodCondBold';
	src: url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondBold.eot) format('eot');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondBold.woff) format('woff');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondBold.woff2) format('woff2');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondBold.ttf) format('truetype');
	font-display: swap;
	font-style: normal;
	font-weight: 600 700;
	font-weight: bold;
}

@font-face {
	font-family: 'GoodCondUltra';
	src: url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondUltra.eot) format('eot');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondUltra.woff) format('woff');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondUltra.woff2) format('woff2');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-CondUltra.ttf) format('truetype');
	font-display: swap;
	font-style: normal;
	font-weight: 900;
	font-weight: bolder;
}

@font-face {
	font-family: 'GoodUltra';
	src: url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Ultra.eot) format('eot');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Ultra.woff) format('woff');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Ultra.woff2) format('woff2');
	src: local(☺︎), url(https://storytelling.blick.ch/infografik/resources/fonts/Good/GoodW05-Ultra.ttf) format('truetype');
	font-display: swap;
	font-style: normal;
	font-weight: 900;
	font-weight: bolder;
}

/* BLICK Farben */
.blick_red { background-color:#e20000; }
.blick_darkred { background-color:#9a0b04; }
.blick_yellow { background-color:#fec603; }
.blick_silver { background-color:#eeeeee; }
.blick_chalk { background-color:#dddddd; }
.blick_lightgrey { background-color:#cccccc; }
.blick_midgrey { background-color:#888888; }
.blick_grey { background-color:#666666; }
.blick_black { background-color:#222222; }
.blick_pitchblack { background-color:#000000; }
.blick_purple { background-color:#831262; }
.blick_green { background-color:#1b7c27; }
.blick_blue { background-color:#2293c2; }


body, html {
	padding: 0;
	margin: 0;
	font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
	font-size: 15px;
	line-height: 1.4em;
	font-weight: normal;
}

body {
	background: #fff;
	position: relative;
	text-align: left;
}

p {
	margin: 0;
	margin-bottom: 0.75em;
}

b {
	font-weight: 600;
}

.lead {
	font-weight: 100;
	padding: 12px;
	margin: 12px 0;
	line-height: 1.3em;
}

.clear {
	position: relative;
	display: block;
	float: none;
	clear: both;
	height: 0px;
	width: 0px;
	line-height: 0;
}

.clearfix {
 	content: "";
	clear: both;
	display: block;
	visibility: hidden;
	height: 0px;
}

a, a:active, a:visited {
	text-decoration: none;
	color: initial;
}

a:hover {
	text-decoration: initial;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 1.3em;
	line-height: 1.3em;
	margin-bottom: 0.5em;
	font-weight: bold;
}


/* BUTTONS */
.button {
	font-size: 14px;
	font-weight: normal;
	min-width: 1px;
	display: inline-block;
	/*padding: 0.5em 0.75em 0.525em 0.75em;
	margin: 0.5em 0.5em 0.25em 0.5em;*/
	padding: 5px 8px;
	margin: 5px 4px;
	border: 1px solid #999;
	background-color: #e0e0e0;
	color: #666;
	border-radius: 3px;
	cursor: pointer;
	display: block;
	line-height: 1.1em;
}

.blick-header {
	position: relative;
	background-color: #e2001a;
	font-family: 'GoodCondUltra', 'Arial Narrow', Impact, sans-serif;
	font-style: normal;
	font-weight: bolder;
	font-size: 1.875rem;
	line-height: 1.1;
	letter-spacing: 0.05625rem;
	padding: 10px 36px 8px 10px;
	margin: 0;
	position: relative;
	color: #ffffff;
	text-transform: uppercase;
}

.header-container {
	position: relative;
}

.blick-footer {
	text-align: center;
	z-index: 200;
	font-size: 13px;
	line-height: 1.2em;
	background-color: #eeeeee;
	color: #999;
	margin: 0;
	margin-top: 1em;
	padding: 4px 8px;
	box-sizing: border-box;
}

.content {
	position: relative;
	text-align: left;
	background-color: #f5f5f5;

}

.main {
	margin: 0 auto;
	position: relative;
	/* max-width: 640px; */
	text-align: left;
	/* padding: 12px; */
}



ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}

.update {
	font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
	display: inline-block;
	font-size: 14px;
	color: #666;
	position: relative;
	font-weight: normal;
}

.section {
	padding: 0px 0px;
}

.section:after {
	content: ".";
	clear: both;
	display: block;
	visibility: hidden;
	height: 0px;		
}

h1 {
	font-family: 'GoodUltra', 'Arial Narrow', Impact, sans-serif;
	font-style: normal;
	font-weight: bolder;
	font-size: 48px;
}

h2 {
	display: inline-block;
	font-family: 'GoodCondUltra', 'Arial Narrow', Impact, sans-serif;
	font-style: normal;
	font-weight: bolder;
	font-size: 26px;
	line-height: 1.2em;
	padding: 0;
	width: 100%;
	margin: 0.1em 0;
}

h3 {
	font-family: 'GoodCondBold', 'Arial Narrow', Impact, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 1.2em;
	/* display: none; */
}


.loading {
	position: relative;
	font-size: 14px;
	text-align: center;
	height: 50vh;
}
.spinner-ring {
	display: inline-block;
	position: relative;
	width: 42px;
	height: 42px;
	margin: 0 12px 0 0;
	top: 33%;
}
.spinner-ring:after {
	clear: both;
	display: block;
	visibility: hidden;
	height: 0px;

}
.spinner-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 42px;
	height: 42px;
	margin: 0px;
	border: 4px solid #000;
	border-radius: 50%;
	animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #999 transparent transparent transparent;
}
.spinner-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinner-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinner-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes spinner-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.menu-wrapper {
	padding: 6px;
	padding-bottom: 3px;
	box-sizing: border-box;
	text-align: center;
	border-bottom: 1px solid #ccc;
	margin-bottom: 12px;
}

.menu-wrapper-closed {
	display: none;
}

.section-items {
	position: relative;
	display: block;
	box-sizing: border-box;
	padding: 12px;
	columns: 25% 4;
}

.item {
	position: relative;
	font-weight: 400;
	width: calc(25% - 12px);
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	padding: 12px;
	margin: 6px;
	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 3px;
	transition: border linear 0.2s;
	cursor: pointer;
}

.item a {
	display: block;
}

.item:hover {
	border-color: #e20000;
}

.img-container {
	position: relative;
	display: block;
	width: 100%;
	border: 1px solid #eee;
	background-color: #f5f5f5;
	background-size: cover;
	background-position: center;
}

.nr {
	position: absolute;
	bottom: 0;
	right: 0;
	color: #ddd;
	padding: 0 3px;
	font-size: 13px;
	background-color: #fff;
	transition: color linear 0.2s;
}

.nr:hover {
	color: #333;
}

h1 {
	margin: 12px 6px 0 6px;
}

h2 {
	margin-top: 12px;
	margin-bottom: 6px;
	/* color: #e20000; */
}

#nav {
	/* position: relative; */
	padding: 12px;
	box-sizing: border-box;
	width: 100%;
	left: 0px;
	background-color: white;
	z-index: 10;
	position: relative;
	display: block;
	border-bottom: 1px solid #ddd;
}

#nav.sticky {
	position: fixed;
	top: 0;
}

.top {
	color: #999;
	margin: 12px 0;
	position: relative;
	/* float: right; */
	display: block;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	transition: color linear 0.2s;
	text-decoration: underline;
}

.top:hover {
	color: #e20000;
}


.textItems .item {
	width: 100%;
	/* background: none; */
}

.textItems .item h2 {
	margin-top: 0px;
}

.pseudoLink {
	font-weight: 600;
	color: #888;
	columns: inherit;
	transition: color linear 0.2s;
	position: relative;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.1em;
}
.item:hover .pseudoLink {
	text-decoration: none;
	color: #e20000;
}

.flag {
	display: inline-block;
	background-color: #ddd;
	border-radius: 3px;
	padding: 1px 6px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 6px;
	margin-right: 6px;
	font-size: 13px;
}

.flag.blickHome {
	background-color: #e20000;
	color: #fff !important;
}
.flag.panoramas {
	/* background-color: rgb(168, 196, 240); */
	background-color: #ffcc00;
	color: #fff !important;
}

.flag.panoramas {
	margin-left: 6px;
}

.flag.blickHome a {
	color: #fff;
}

.nav .flag {
	cursor: pointer;
}

.flag.inactive {
	opacity: 0.25;
}

.flag:empty {
	display: none;
}

.type-infografik .flag-type {
	background-color: #bada55;
}
.type-longform .flag-type {
	background-color: orange;
}

.flag-type-infografik {
	background-color: #bada55;
}
.flag-type-longform {
	background-color: orange;
}

.flag-year {
	background-color: #333;
	color: #fff;
}

.description {
	margin: 6px 0 12px 0;
	font-size: 16px;
	line-height: 1.2em;;
}
/* 
a, a:visited, a:active {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: none;
} */

.link {
	display: inline-block;
	background-color: #ddd;
	border-radius: 3px;
	padding: 3px 12px;
	font-weight: 600;
	background-color: #e20000;
	color: #fff;
	transition: color linear 0.2s, background-color linear 0.2s;
}

.link:hover {
	color: #e20000;
	background-color: #f5f5f5;
}

.canihaz {
	display: none;
}

@media only screen and (max-width: 700px) {
	.main {
		text-align: center;
	}
	.item {
		text-align: left;
	}
}

@media only screen and (max-width: 1400px) {
	.section-items {
		columns: 33.3% 3;
	}
	.item {
		width: calc(33.3% - 12px);
	}
}
@media only screen and (max-width: 1200px) {
	.section-items {
		columns: 50% 2;
	}
	.item {
		width: calc(50% - 12px);
	}
}

@media only screen and (max-width: 700px) {
	.section-items {
		columns: 100% 1;
	}
	.item {
		width: calc(100% - 12px);
	}
}